// extracted by mini-css-extract-plugin
export var cardDate = "style-module--cardDate--eadaf";
export var cardMessage = "style-module--cardMessage--6bf3c";
export var cardStatus = "style-module--cardStatus--55177";
export var cardTitle = "style-module--cardTitle--f17b0";
export var confirmed = "style-module--confirmed--03022";
export var content = "style-module--content--91480";
export var pageTitle = "style-module--pageTitle--8523a";
export var pageWrapper = "style-module--pageWrapper--e4081";
export var pending = "style-module--pending--21286";
export var tab = "style-module--tab--e017b";
export var tabMenu = "style-module--tabMenu--9c07e";
export var tag = "style-module--tag--9d39f";
export var tagList = "style-module--tagList--a367a";